import { useState } from 'react';
import headerPic from './assets/demo.jpg';
import drop from './assets/first_drop.png';
import { Popover } from 'react-tiny-popover';

const Header = () => {
  return (
    <div className="header-wrapper">
      <div className="row">
        <div className="col-md-7 header-text-wrapper">
          <h1 className="main-title">
            Most of shitcoins projects are 💩
            <br />
            <span className="pink">We are here to clean 🧻 </span>
          </h1>
          <h3 className="sub-title">
            Let's build the cleanest Community ✨
          </h3>
          <hr />

          <h3 className="sub-title">
            WIPE. The pandemic has shown the world the true value of toilet paper.{' '}
            <br />
            Get ready for the apocalypse! WIPE.
          </h3>
          <hr />
          <h3 className="main-title">
            Wipe the chart.
            <br /> <span className="pink">WipeMyAss!</span>
          </h3>
          {/* <div className="countdown-wrapper">
            <Countdown />
          </div> */}
          <div className="mint-button-wrapper" disabled>
            <a
              href="https://birdeye.so/token/9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6?chain=solana"
              target="_blank"
              rel="noreferrer"
            >
              <button className="btn btn-primary main-mint-btn">
                <i className="fas fa-toilet-paper"></i>
                &nbsp; BUY ON BIRDEYE
              </button>
            </a>
            {/* <p className="sub-text">
              Wallet needs to be connected first before clicking Mint. <br />
              We advise you to connect your wallet using the button in the top
              menu before the event starts.
            </p> */}
            <h3 className="sub-text">
              We will burn WipeMyAss token everyday {' '}
              <b className="pink">EVERYDAY </b>until christmas
              {/* <Popover
                isOpen={isPopoverOpen}
                positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
                content={
                  <img src={drop} alt="ThugBirdz" style={{ width: '200px' }} />
                }
              >
                <span
                  onMouseEnter={() => setIsPopoverOpen(true)}
                  onMouseLeave={() => setIsPopoverOpen(false)}
                  className="fakeLink"
                >
                  Thugbirdz #1654
                </span>
              </Popover> */}
            </h3>
          </div>
        </div>

        <div className="col-md-5 header-pic-wrapper">
          <img className="header-pic" src={headerPic} alt="WipeMyAss" />
        </div>
      </div>
    </div>
  );
};

export default Header;
