import { useState, useEffect, useCallback } from 'react';
import chart from './assets/wipe_chart.png';
import cm from './assets/coinmarketcap.svg';
import cg from './assets/coingecko.webp';
import birdeye from './assets/birdeye.png';
import cropper from './assets/cropper.svg';

import PopoverText from './PopoverText';

const Token = () => {
  const start = 8000000000;
  const burntPerS = 57;
  const [tokensLeft, setTokensLeft] = useState(start);

  const getTokenLeft = useCallback(() => {
    const startingDate = new Date(Date.UTC(2021, 10, 3, 0, 0, 0));
    const now = new Date();
    const diff = (now.getTime() - startingDate.getTime()) / 1000;
    setTokensLeft(start - diff * burntPerS);
  }, []);

  useEffect(() => {
    getTokenLeft();
    const interval = setInterval(() => {
      getTokenLeft();
    }, 800);
    return () => clearInterval(interval);
  }, [getTokenLeft]);

  return (
    <div className="token-wrapper">
      <div
        className="main-title bigger centered"
        style={{ marginTop: '25px', marginBottom: '0px' }}
      >
        <span className="pink">$WIPE</span> TOKEN
        {/* <br />
        <span style={{ fontSize: '0.8em' }}>14,115.36% APY</span> */}
      </div>
      <div className="centered">
        <a
          href="https://coinmarketcap.com/currencies/wipe-my-ass/"
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn market-btn">
            <img src={cm} alt="CoinMarketCap" />
          </button>
        </a>
        <a
          href="https://www.coingecko.com/en/coins/wipemyass"
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn market-btn">
            <img src={cg} alt="CoinGecko" />
          </button>
        </a>
      </div>
      <div className="centered">
        <a
          href="https://trade.dexlab.space/#/market/3kuUc5eTZyi7qajuFfDMMUUkqreEkUKtxQbVCjdriKVz"
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn trade-btn">
            TRADE $WIPE ON{' '}
            <img
              src={birdeye}
              alt="Birdeye"
              style={{ marginTop: '-5px', height: '28px' }}
            />
          </button>
        </a>
       
      </div>

      {/* <div className="token-panels-wrapper" style={{ width: '100vw' }}>
        <div
          className="token-panel"
          style={{ flexGrow: 1, width: '100%', maxWidth: '1000px' }}
        >
          <div className="token-panel-title">Farming</div>
          <div className="token-panel-text">
            The quickest way to earn some <b className="pink">$WIPE</b> is to
            Farm on <a href="/stake">Cropper</a>.
            <br />
            Get an <b className="pink">insane boosted APY</b> as an early farmer
            by farming some <b className="pink">$WIPE</b> right now! 💰💰👨‍🌾💰💰
            <br />
            <br />
            The more <b className="pink">$WIPE</b> you stake the more{' '}
            <b className="pink">$WIPE</b> you get. &nbsp;
            <br /> 🧻 + 🧻 = 🧻🧻🧻🧻
            <br />
            <br />
            <a href="/stake">
              <button className="btn" style={{ fontSize: '1.2em' }}>
                🚀 Start farming here
              </button>
            </a>
          </div>
        </div>
      </div> */}

      <div className="token-panels-wrapper">
        <div className="token-panel">
          <div className="token-panel-title"> NOT MINTABLE ANYMORE</div>
          <div className="token-panel-text">   
          💎
          </div>
        </div>
        <div className="token-panel">
          <div className="token-panel-title">OWNERSHIP RENOUNCED</div>
          <div className="token-panel-text">
          🛡
          </div>
        </div>
      </div>
      <div className="chart-wrapper">
        <img src={chart} alt="Wipe Token Chart" />
      </div>

      <div className="token-counter-wrapper">
        <div className="counter">
          {parseInt(tokensLeft.toFixed(0)).toLocaleString('us-US')}
          <br />
          $WIPE LEFT
        </div>
      </div>
    </div>
  );
};

export default Token;
