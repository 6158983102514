import './Stake.css';

import buyWipe from './assets/stake/buy-wipe.gif';
import addLiquidity from './assets/stake/add-liquidity.gif';
import farm from './assets/stake/farm.gif';
import harvest from './assets/stake/harvest.gif';
import unfarm from './assets/stake/unfarm.gif';
import removeLiquidity from './assets/stake/remove-liquidity.gif';

import { useState } from 'react';

const Stake = () => {
  const [showOut, setShowOut] = useState(false);

  return (
    <div>
      <div className="container-stake app-wrapper stake-wrapper">
        <div className="stake-main-text">
          You want to make a ton of 💰 with your{' '}
          <span className="pink">$WIPE</span>?
          <br />
          <br />
          Follow the next steps and we will teach you how to be a 🧻
          millionaire!
        </div>
        <div className="stake-step">
          <div className="stake-step-title">
            1. Buy some <span className="pink">$WIPE</span> 🚀
          </div>
          <img src={buyWipe} alt="Buy Wipe" className="stake-step-pic" />
          <div className="stake-step-text">
            You will need the same worth of USDC and{' '}
            <b className="pink">$WIPE</b> to start your farm.
            <br />
            Click{' '}
            <a
              href="https://cropper.finance/swap/?from=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&to=9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6"
              rel="noreferrer"
              target="_blank"
              className="pink"
            >
              here
            </a>
            &nbsp; to go on{' '}
            <a
              href="https://cropper.finance/swap/?from=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&to=9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6"
              rel="noreferrer"
              target="_blank"
              className="pink"
            >
              Cropper
            </a>
            &nbsp; and Swap some USDC to get some <b className="pink">$WIPE</b>.
            <br />
            This step is optional if you already hold some{' '}
            <b className="pink">$WIPE</b>.
          </div>
        </div>
        <div className="stake-step">
          <div className="stake-step-title">2. Add some liquidity 💸</div>
          <img src={addLiquidity} alt="Buy Wipe" className="stake-step-pic" />
          <div className="stake-step-text">
            Still on Cropper click on&nbsp;
            <a
              href="https://cropper.finance/pools/"
              className="pink"
              rel="noreferrer"
              target="_blank"
            >
              Pools
            </a>{' '}
            in the top menu.
            <br />
            Search for the <b className="pink">$WIPE</b> pool (you need to set
            the filter to <b className="pink">All</b> or{' '}
            <b className="pink">Permissionless</b>).
            <br />
            Click on the <b className="pink">+</b> Icon on the Pool. Then input
            some USDC and some <b className="pink">$WIPE</b>.
          </div>
        </div>
        <div className="stake-step">
          <div className="stake-step-title">3. Farm ! 🌽👨‍🌾🌽</div>
          <img src={farm} alt="Buy Wipe" className="stake-step-pic" />
          <div className="stake-step-text">
            Still on Cropper click on&nbsp;
            <a
              href="https://cropper.finance/farms/"
              className="pink"
              rel="noreferrer"
              target="_blank"
            >
              Farms
            </a>{' '}
            in the top menu.
            <br />
            Search for the <b className="pink">$WIPE</b> farm (you need to set
            the filter to <b className="pink">All</b> or{' '}
            <b className="pink">Permissionless</b>).
            <br />
            Click on the first&nbsp;<b className="pink">$WIPE</b> farm (the one
            with the most Liquidity).
            <br />
            Then click on <b className="pink">Stake LP</b>.
            <br />
            In the pop-up enter an amount or click on{' '}
            <b className="pink">Max</b>. Then confirm the transaction in your
            wallet.
          </div>
        </div>

        <div className="stake-step">
          <div className="stake-step-title">4. Harvest ! 💰💰👨‍🌾💰💰</div>
          <img src={harvest} alt="Buy Wipe" className="stake-step-pic" />
          <div className="stake-step-text">
            At any time see your pending rewards and claim them by clicking on
            Harvest.
            <br />
            <br />
            The more USDC and <b className="pink">$WIPE</b> you stake the more{' '}
            <b className="pink">$WIPE</b> you get. 🧻 💰
          </div>
        </div>

        <div className="stake-main-text">
          Now let it work for you. 🚀
          <br />
          <br />
        </div>
        <button
          style={{ width: '250px', fontSize: '1.1em', padding: '10px' }}
          className="btn"
          onClick={() => {
            setShowOut(true);
            setTimeout(() => {
              document.getElementById('out-step-1').scrollIntoView();
            }, 500);
          }}
        >
          💸 Want to Cash out?
        </button>
        {showOut && (
          <>
            <div className="stake-step">
              <div className="stake-step-title" id="out-step-1">
                1. Unstake your coins
              </div>
              <img src={unfarm} alt="Buy Wipe" className="stake-step-pic" />
              <div className="stake-step-text"></div>
            </div>
            <div className="stake-step">
              <div className="stake-step-title">2. Remove liquidity</div>
              <img
                src={removeLiquidity}
                alt="Buy Wipe"
                className="stake-step-pic"
              />
              <div className="stake-step-text"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Stake;
