import './Timeline.css';
import drop from './assets/first_drop.png';

const Timeline = () => {
  return (
    <div className="timeline-wrapper">
      <ul className="timeline">
        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="flag">$WIPE Token</span>
            </div>
            <div className="desc">
              Listed on Dex.
              <br />
              Cleanest token ever made.
              <br />
              <br />
              Wipe Shiba's ass            
            </div>
          </div>
        </li>

        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="flag">Toilet Paper hype</span>
            </div>
            <div className="desc">
              <b>Degen realize that wipe is the new alpha</b>
              <br />
              <br />
              wipe wipe.
            </div>
          </div>
        </li>
        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="flag">MEXC LISTING</span>
            </div>
            <div className="desc">We are so clean. CEX Listing guaranteed</div>
          </div>
        </li>
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="flag">Flip Apple Mcap</span>
            </div>
            <div className="desc">
              WipeMyAss will flip Apple Market Cap{' '}
              <b className="pink">don't fade 🚀 </b>
            </div>
          </div>
        </li>

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="flag">Create our own country</span>
            </div>
            <div className="desc">
              <b className="pink"></b> We will outperform US and China.
            </div>
            {/* <div className="desc">
              The first drop will be Thugbirdz #1654
              <img
                src={drop}
                alt="First WipePass drop"
                style={{ width: '50%', margin: '10px', zIndex: 9999 }}
              />
            </div> */}
          </div>
        </li>

        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="flag">Toilet Paper to Mars</span>
            </div>
            <div className="desc">
              Wiping Alien's asses
              <span className="pink"> $WIPE</span>
            </div>
            {/* <div className="desc">
              Check <a href="/stake">our guide</a> to get started.
            </div> */}
          </div>
        </li>

        <li>
          <div className="direction-l">
            <div className="flag-wrapper">
              <span className="flag">CZ as CEO</span>
            </div>
            <div className="desc">
              WipeMyAss was initially CZ secret project
            </div>
          </div>
        </li>
        <li>
          <div className="direction-r">
            <div className="flag-wrapper">
              <span className="flag">Brazzers Sponsoring</span>
            </div>
            <div className="desc">
              Wipe will sponsor brazzers studio in california
            </div>
          </div>
        </li>
      </ul>
    </div>
  ); /*  */
};

export default Timeline;
