import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

const SubHeader = () => {
  return (
    <div className="subheader-wrapper">
      <div className="percent">
        <CountUp start={0} end={77} duration={1}>
          {({ countUpRef, start }) => (
            <VisibilitySensor onChange={start} delayedCall>
              <span ref={countUpRef} />
            </VisibilitySensor>
          )}
        </CountUp>
        %
      </div>
      <div className="subheader-text">
       of WipeMyAss Token is  <br />
        already fucking <span className="pink">
          BURN
        </span>{' '}
        !
      </div>
    </div>
  );
};

export default SubHeader;
