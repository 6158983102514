import Header from './Header';
import Timeline from './Timeline';
import Faq from './Faq';
import SubHeader from './SubHeader';
import Token from './Token';
import Team from './Team';
import Footer from './Footer';

const Home = () => {
  return (
    <div>
      <div className="container-fluid header-top-wrapper">
        <Header />
      </div>
      <SubHeader></SubHeader>
      <Token />
      <div className="container app-wrapper">
        <Timeline />
      </div>
      <Team />
      <Footer></Footer>
    </div>
  );
};

export default Home;
