import { Link } from 'react-router-dom';

const Banner = () => {
  return (
    <div className="banner-wrapper">
      <Link to="/" className="logo-link">
        <div className="logo-wrapper">
          {/* <img src={logo} alt="WIPEMYASS" className="logo" /> */}
          <div className="logo-text">WipeMyAss</div>
        </div>
      </Link>
      <div className="links">
        {/* <Wallet></Wallet> */}
        {/* <Link to="/index" className="btn btn-link">
          <i className="far fa-gem"></i>
          Rarity Index
        </Link> */}

        <a
          className="btn btn-link"
          href="https://twitter.com/WipeMyAssSolana"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-twitter"></i>
          Twitter
        </a>
        <a
          className="btn btn-link"
          href="https://discord.gg/q96Pekxzg5"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-discord"></i>
          Discord
        </a>
        <a className="btn btn-link" href="https://solscan.io/token/9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6"         
         target="_blank"
          rel="noreferrer"
        >
          <i className="fas fa-gem"></i>
          SolScan
        </a>
      </div>
    </div>
  );
};

export default Banner;
