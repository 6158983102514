const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="links">
        <a
          className="btn btn-link"
          href="https://twitter.com/WipeMyAssSolana"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-twitter"></i>
          Twitter
        </a>
        <a
          className="btn btn-link"
          href="https://discord.com/invite/q96Pekxzg5"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-discord"></i>
          Discord
        </a>
      </div>
    </div>
  );
};

export default Footer;
