import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './App.css';
import Banner from './Banner';
import Home from './Home';
import Rarity from './Rarity';
import Stake from './Stake';

function App() {
  return (
    <Router>
      <div className="container-fluid">
        <Banner></Banner>
      </div>
      <Switch>
        <Route path="/stake">
          <Stake />
        </Route>
        <Route path="/rarity">
          <Rarity />
        </Route>

        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
