import rarity from './assets/rarity-nobg.png';

const Rarity = () => {
  return (
    <div style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
      <img
        src={rarity}
        alt=""
        style={{ width: '100vw', maxWidth: '1700px', marginBottom: '40px' }}
      />
    </div>
  );
};

export default Rarity;
