const Faq = () => {
  return (
    <div className="faq-wrapper">
      <ul>
        <li>
          <p className="question">How will I get my rewards?</p>
          <p className="answer">
            We take weekly snapshots of all holder wallets and send rewards
            straight in your wallets. <br />
            <b className="pink">You have nothing to do.</b>
          </p>
        </li>
        <li>
          <p className="question">What is the weekly reward snapshot time?</p>
          <p className="answer">
            The snapshot is taken randomly during the week, we do not
            communicate on the exact time.
          </p>
        </li>

        <li>
          <p className="question">How can I join WIPE DAO?</p>
          <p className="answer">
            Just hold <b className="pink">1 WipeMyAss NFT</b> and{' '}
            <b className="pink">250,000 $WIPE</b> Tokens.
          </p>
        </li>

        <li>
          <p className="question">Where can I get $WIPE Token?</p>
          <p className="answer">
            You can get your <b className="pink">$WIPE</b> by{' '}
            <a
              href="https://trade.dexlab.space/#/market/3kuUc5eTZyi7qajuFfDMMUUkqreEkUKtxQbVCjdriKVz"
              target="_blank"
              rel="noreferrer"
            >
              Trading on DexLab
            </a>
            &nbsp; or &nbsp;
            <a
              href="https://cropper.finance/swap/?from=EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v&to=9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6"
              target="_blank"
              rel="noreferrer"
            >
              Swapping on Cropper
            </a>
          </p>
        </li>

        <li>
          <p className="question">Where can I get WipeMyAss NFT?</p>
          <p className="answer">
            You can get your WipeMyAss NFT on{' '}
            <a
              href="https://magiceden.io/marketplace/wipemyass"
              target="_blank"
              rel="noreferrer"
            >
              Magic Eden
            </a>
            ,&nbsp;
            <a
              href="https://digitaleyes.market/collections/%20WipeMyAss"
              target="_blank"
              rel="noreferrer"
            >
              DigitalEyes
            </a>
            &nbsp;and&nbsp;
            <a
              href="https://solsea.io/collection/618acc65ecbddd404827ca64"
              target="_blank"
              rel="noreferrer"
            >
              Solsea
            </a>
          </p>
        </li>
        <li>
          <p className="question">Why should I hold WipeMyAss NFT?</p>
          <p className="answer">
            By holding our NFT you will get weekly 3,000 $WIPE reward and help
            us clean the world ✨ ❤️
          </p>
        </li>

        <li>
          <p className="question">
            I need to clean mine really bad, can I get more than 1 WipeMyAss
            NFT?
          </p>
          <p className="answer">
            Yes. The more NFTs you hold the more $WIPE you got.
            <br />
            For each NFT you'll receive <b className="pink">3,000 $WIPE/week</b>
            <br />
            <br />
            Stay clean ✨
          </p>
        </li>
        <li>
          <p className="question">What's WipeMyAss NFT total supply? </p>
          <p className="answer">Only 3000 will be released</p>
        </li>

        {/* <li>
          <p className="question">Which wallet can I use?</p>
          <p className="answer">
            You will be able to mint WipeMyAss with Phantom (recommended),
            Solflare and Sollet.io
          </p>
        </li> */}
      </ul>
    </div>
  );
};

export default Faq;
