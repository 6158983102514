import alexandre from './assets/team/alexandre.png';
import miguel from './assets/team/miguel.svg';
import roman from './assets/team/roman.svg';
import elena from './assets/team/elena.svg';
import gab from './assets/team/gab.svg';

const Team = () => {
  return (
    <div className="team-section">
      <h2 className="section-title">The Wipers 💩 ❤️</h2>
      <div className="team-wrapper">
        <div className="member">
          <img src={alexandre} alt="" className="team-pic" />
          <div className="team-name">
            Alexander
            <br /> Lord McButt
          </div>
          <div className="team-title">Website Wizard</div>
        </div>

        <div className="member">
          <img src={roman} alt="" className="team-pic" />
          <div className="team-name">
            Roman
            <br /> Jason Wipeham
          </div>
          <div className="team-title">Discord Master</div>
        </div>

        <div className="member">
          <img src={miguel} alt="" className="team-pic" />
          <div className="team-name">
            Miguel
            <br /> Wipe Khalifa
          </div>
          <div className="team-title">Token Master</div>
        </div>

        <div className="member">
          <img src={elena} alt="" className="team-pic" />
          <div className="team-name">
            Elena
            <br /> Greta Thunwipe
          </div>
          <div className="team-title">Wipes Creator</div>
        </div>

        <div className="member">
          <img src={gab} alt="" className="team-pic" />
          <div className="team-name">
            Gab
            <br /> Mark Zuckerbutt
          </div>
          <div className="team-title">Blockchain Professor</div>
        </div>
      </div>
    </div>
  );
};

export default Team;

// Lord McButt
// Jason Wipeham
// Wipe Pitt
// Angeliwipe Jolie
// Mark Zuckerwipe
// Wipe Jong Un
